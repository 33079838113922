import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import Layout from "../components/layout"

const About = () => {
    const { title, description } = useSiteMetadata()
    return (
      <Layout>
        <div>
            <h1>Welcome to {title}</h1>
            <p>About: {description}</p>
        </div>
      </Layout>
    )
}

export default About
